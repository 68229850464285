import React, { useContext, useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import Recaptcha from "react-google-recaptcha";

import { encode } from "../../utils";
import { ThemeContext } from "../../ThemeContext";

const RECAPTCHA_KEY =
  process.env.GATSBY_APP_SITE_RECAPTCHA_KEY || process.env.SITE_RECAPTCHA_KEY;
if (typeof RECAPTCHA_KEY === "undefined") {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `);
}

export const ContactUsForm = () => {
  const [state, setState] = React.useState({});
  const [buttonHovered, setButtonHovered] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const { colors, setErrorNotification, mobile } = useContext(ThemeContext);
  const recaptchaRef = React.createRef();

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleRecaptchaChange = value => {
    if (value) {
      setErrorNotification({ error: false, message: "" });
    }
  };

  const toggleButtonHover = () => {
    setButtonHovered(!buttonHovered);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      return setErrorNotification({
        error: true,
        message:
          "Por favor, marque o campo “eu não sou um robô” para continuar.",
      });
    }

    setButtonClicked(true);

    axios("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() =>
        navigate(form.getAttribute("action"), {
          state: { name: state.name },
        })
      )
      .catch(
        error =>
          setErrorNotification({
            error: true,
            message: "something went wrong",
          }) && setButtonClicked(false)
      );
  };

  return (
    <div style={styles.sectionWrapper({ colors, mobile })}>
      <h1 style={styles.title({ colors })}>Entre em contato</h1>
      <form
        style={styles.form({ colors })}
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        <div style={styles.inputFieldsSection}>
          <input type="hidden" name="form-name" value="contact" />
          <input
            type="text"
            name="name"
            placeholder="Nome*"
            onChange={handleChange}
            style={styles.inputField}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Telefone*"
            onChange={handleChange}
            style={styles.inputField}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            style={styles.inputField}
          />
        </div>
        <div style={styles.texAreaSection}>
          <textarea
            type="text"
            name="message"
            placeholder="Mensagem*"
            onChange={handleChange}
            style={styles.textArea}
            required
          />
        </div>
        <div style={styles.captchaSection}>
          <Recaptcha
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            hl="pt-BR"
            onChange={handleRecaptchaChange}
          />
          <button
            typ="submit"
            onMouseEnter={toggleButtonHover}
            onMouseLeave={toggleButtonHover}
            style={styles.button({ colors, hovered: buttonHovered })}
            disabled={buttonClicked}
          >
            ENVIAR
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  sectionWrapper: ({ colors, mobile }) => ({
    textAlign: "center",
    margin: mobile ? "10px" : "80px",
    backgroundColor: colors.lightBlue,
    borderRadius: "8px",
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.5)",
    padding: mobile ? "8px" : "32px 32px 0",
  }),
  title: ({ colors }) => ({
    color: colors.white,
    textTransform: "uppercase",
    paddingBottom: "12px",
  }),
  form: ({ colors }) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    color: colors.green,
  }),
  inputFieldsSection: {
    width: "40%",
    minWidth: "240px",
  },
  texAreaSection: {
    minWidth: "240px",
    maxWidth: "40%",
    flexGrow: 1,
    margin: "8px",
  },
  inputField: {
    padding: "10px",
    border: "none",
    borderRadius: "5px",
    margin: "8px 0",
    width: "100%",
    fontSize: "18px",
    color: "inherit",
  },
  textArea: {
    padding: "10px",
    border: "none",
    borderRadius: "5px",
    maxWidth: "100%",
    minWidth: "100%",
    height: "100%",
    minHeight: "100px",
    maxHeight: "300px",
    fontSize: "18px",
    color: "inherit",
  },
  captchaSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 4px",
  },
  button: ({ colors, hovered }) => ({
    fontSize: "20px",
    margin: "30px",
    padding: "10px 20px",
    backgroundColor: hovered ? colors.green : colors.lightGreen,
    color: colors.white,
    border: "none",
    boxShadow: hovered ? "none" : "0 0 8px 0 rgba(0,0,0,0.5)",
    cursor: "pointer",
    borderRadius: "5px",
  }),
};
